export class SharePointService {
  constructor(graphClient) {
    this.graphClient = graphClient;
    this.siteId = process.env.REACT_APP_SHAREPOINT_SITE_ID;
    this.listId = process.env.REACT_APP_SHAREPOINT_LIST_ID;
  }

  // Helper method to format review data for SharePoint
  formatReviewForSharePoint(reviewData) {
    const now = new Date().toISOString();
    return {
      fields: {
        Title: `Performance Review - ${reviewData.employeeName} - ${reviewData.reviewYear}`,
        EmployeeId: reviewData.employeeId,
        ManagerId: reviewData.managerId,
        ReviewYear: reviewData.reviewYear,
        Status: reviewData.status || 'Draft',
        ReviewData: JSON.stringify(reviewData),
        ReviewDate: reviewData.reviewDate || now,
        LastModifiedDate: now,
        EmployeeName: reviewData.employeeName,
        ManagerName: reviewData.managerName,
        Department: reviewData.department,
        ReviewType: reviewData.reviewType || 'Annual',
        ReviewScore: reviewData.overallAssessment?.rating,
        NextReviewDate: reviewData.nextReviewDate
      }
    };
  }

  // Helper method to parse SharePoint response
  parseSharePointResponse(response) {
    const fields = response.fields;
    return {
      id: response.id,
      ...JSON.parse(fields.ReviewData),
      status: fields.Status,
      lastModified: fields.LastModifiedDate,
      nextReviewDate: fields.NextReviewDate
    };
  }

  async saveReview(reviewData) {
    try {
      const spItem = this.formatReviewForSharePoint(reviewData);
      
      const response = await this.graphClient
        .api(`/sites/${this.siteId}/lists/${this.listId}/items`)
        .post(spItem);

      // Handle attachments if present
      if (reviewData.attachments?.length > 0) {
        await this.uploadAttachments(response.id, reviewData.attachments);
      }

      return this.parseSharePointResponse(response);
    } catch (error) {
      console.error('Error saving to SharePoint:', error);
      throw new Error('Failed to save review to SharePoint');
    }
  }

  async uploadAttachments(itemId, attachments) {
    try {
      for (const file of attachments) {
        await this.graphClient
          .api(`/sites/${this.siteId}/lists/${this.listId}/items/${itemId}/attachments`)
          .post({
            name: file.name,
            contentType: file.type,
            content: file.content
          });
      }
    } catch (error) {
      console.error('Error uploading attachments:', error);
      throw new Error('Failed to upload attachments');
    }
  }

  async getReview(itemId) {
    try {
      const response = await this.graphClient
        .api(`/sites/${this.siteId}/lists/${this.listId}/items/${itemId}`)
        .expand('fields')
        .get();

      return this.parseSharePointResponse(response);
    } catch (error) {
      console.error('Error fetching review:', error);
      throw new Error('Failed to fetch review from SharePoint');
    }
  }

  async updateReview(itemId, reviewData) {
    try {
      const spItem = this.formatReviewForSharePoint(reviewData);
      
      const response = await this.graphClient
        .api(`/sites/${this.siteId}/lists/${this.listId}/items/${itemId}`)
        .update(spItem);

      // Handle attachments if present
      if (reviewData.attachments?.length > 0) {
        await this.uploadAttachments(itemId, reviewData.attachments);
      }

      return this.parseSharePointResponse(response);
    } catch (error) {
      console.error('Error updating review:', error);
      throw new Error('Failed to update review in SharePoint');
    }
  }

  async getReviewsByEmployee(employeeId) {
    try {
      const response = await this.graphClient
        .api(`/sites/${this.siteId}/lists/${this.listId}/items`)
        .expand('fields')
        .filter(`fields/EmployeeId eq '${employeeId}'`)
        .orderBy('fields/ReviewDate desc')
        .get();

      return response.value.map(item => this.parseSharePointResponse(item));
    } catch (error) {
      console.error('Error fetching employee reviews:', error);
      throw new Error('Failed to fetch employee reviews from SharePoint');
    }
  }

  async getReviewsByManager(managerId) {
    try {
      const response = await this.graphClient
        .api(`/sites/${this.siteId}/lists/${this.listId}/items`)
        .expand('fields')
        .filter(`fields/ManagerId eq '${managerId}'`)
        .orderBy('fields/ReviewDate desc')
        .get();

      return response.value.map(item => this.parseSharePointResponse(item));
    } catch (error) {
      console.error('Error fetching manager reviews:', error);
      throw new Error('Failed to fetch manager reviews from SharePoint');
    }
  }

  async getReviewsByStatus(status) {
    try {
      const response = await this.graphClient
        .api(`/sites/${this.siteId}/lists/${this.listId}/items`)
        .expand('fields')
        .filter(`fields/Status eq '${status}'`)
        .orderBy('fields/ReviewDate desc')
        .get();

      return response.value.map(item => this.parseSharePointResponse(item));
    } catch (error) {
      console.error('Error fetching reviews by status:', error);
      throw new Error('Failed to fetch reviews by status from SharePoint');
    }
  }

  async deleteReview(itemId) {
    try {
      await this.graphClient
        .api(`/sites/${this.siteId}/lists/${this.listId}/items/${itemId}`)
        .delete();
    } catch (error) {
      console.error('Error deleting review:', error);
      throw new Error('Failed to delete review from SharePoint');
    }
  }

  async getAttachments(itemId) {
    try {
      const response = await this.graphClient
        .api(`/sites/${this.siteId}/lists/${this.listId}/items/${itemId}/attachments`)
        .get();

      return response.value;
    } catch (error) {
      console.error('Error fetching attachments:', error);
      throw new Error('Failed to fetch attachments from SharePoint');
    }
  }
}
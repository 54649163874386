import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Providers } from '@microsoft/mgt-element';
import { Get } from '@microsoft/mgt-react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent,
  Alert,
  AlertDescription 
} from './ui/ui-components';
import { 
  ClipboardList, 
  AlertTriangle,
  Clock,
  CheckCircle2,
  Calendar 
} from 'lucide-react';
import { ROUTERS, REVIEW_STATUS, REVIEW_STATUS_COLORS } from '../lib/constants';


const EmployeeDashboard = () => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const provider = Providers.globalProvider;
        if (provider) {
          // Use Graph API to fetch reviews from SharePoint
          const sharePointService = new SharePointService(provider.graph.client);
          const response = await sharePointService.getReviewsByEmployee();
          setReviews(response);
        }
      } catch (err) {
        setError('Failed to fetch reviews');
        console.error('Error fetching reviews:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const metrics = {
    totalReviews: reviews.length,
    pendingReviews: reviews.filter(r => 
      r.status === REVIEW_STATUS.IN_PROGRESS || 
      r.status === REVIEW_STATUS.PENDING_EMPLOYEE
    ).length,
    completedReviews: reviews.filter(r => 
      r.status === REVIEW_STATUS.COMPLETED
    ).length,
    upcomingReviews: reviews.filter(r => {
      const reviewDate = new Date(r.nextReviewDate);
      const now = new Date();
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(now.getDate() + 30);
      return reviewDate >= now && reviewDate <= thirtyDaysFromNow;
    }).length
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center">Loading dashboard...</div>
      </div>
    );
  }

  const getStatusColor = (status) => {
    const color = REVIEW_STATUS_COLORS[status];
    return {
      bg: `bg-${color}-100`,
      text: `text-${color}-800`
    };
  };

  const handleViewMyReviews = () => {
    navigate(ROUTERS.REVIEWS.EMPLOYEE);
  };

  const handleViewReviewCalendar = () => {
    navigate(ROUTERS.REVIEWS.CALENDAR);
  };

  return (
    <div className="space-y-6 p-6">
      {error && (
        <Alert className="bg-red-50 border-red-200">
          <AlertTriangle className="h-4 w-4 text-red-500" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {/* Metrics Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card>
          <CardContent className="p-6">
            <div className="flex items-center">
              <div className="p-2 bg-blue-50 rounded-lg">
                <ClipboardList className="h-6 w-6 text-blue-500" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-600">Total Reviews</p>
                <p className="text-2xl font-semibold text-gray-900">{metrics.totalReviews}</p>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <div className="flex items-center">
              <div className="p-2 bg-yellow-50 rounded-lg">
                <Clock className="h-6 w-6 text-yellow-500" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-600">Pending Reviews</p>
                <p className="text-2xl font-semibold text-gray-900">{metrics.pendingReviews}</p>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <div className="flex items-center">
              <div className="p-2 bg-green-50 rounded-lg">
                <CheckCircle2 className="h-6 w-6 text-green-500" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-600">Completed</p>
                <p className="text-2xl font-semibold text-gray-900">{metrics.completedReviews}</p>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="p-6">
            <div className="flex items-center">
              <div className="p-2 bg-purple-50 rounded-lg">
                <Calendar className="h-6 w-6 text-purple-500" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-600">Upcoming</p>
                <p className="text-2xl font-semibold text-gray-900">{metrics.upcomingReviews}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card 
          className="hover:bg-gray-50 cursor-pointer transition-colors" 
          onClick={handleViewMyReviews}
        >
          <CardContent className="p-6">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900">My Reviews</h3>
                <p className="mt-1 text-sm text-gray-500">View all your performance reviews</p>
              </div>
              <div className="p-2 bg-blue-50 rounded-lg">
                <ClipboardList className="h-6 w-6 text-blue-500" />
              </div>
            </div>
          </CardContent>
        </Card>

        <Card 
          className="hover:bg-gray-50 cursor-pointer transition-colors" 
          onClick={handleViewReviewCalendar}
        >
          <CardContent className="p-6">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900">Review Calendar</h3>
                <p className="mt-1 text-sm text-gray-500">View upcoming review schedule</p>
              </div>
              <div className="p-2 bg-purple-50 rounded-lg">
                <Calendar className="h-6 w-6 text-purple-500" />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Recent Reviews */}
      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center gap-2">
            <ClipboardList className="h-5 w-5" />
            Recent Reviews
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Review Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Manager
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Next Review
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {reviews.map((review) => (
                  <tr 
                    key={review.id} 
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => navigate(`${ROUTERS.REVIEWS.EMPLOYEE}/${review.id}`)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      {new Date(review.reviewDate).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {review.managerName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                        ${review.status === REVIEW_STATUS.COMPLETED ? 'bg-green-100 text-green-800' : 
                          review.status === REVIEW_STATUS.IN_PROGRESS ? 'bg-yellow-100 text-yellow-800' : 
                          'bg-gray-100 text-gray-800'}`}>
                        {review.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {review.nextReviewDate ? new Date(review.nextReviewDate).toLocaleDateString() : 'Not scheduled'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default EmployeeDashboard;
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { AuthProvider } from './contexts/AuthContext';
import App from './App';
import './styles.css';
import './App.css';

// Initialize Microsoft Authentication
Providers.globalProvider = new Msal2Provider({
  clientId: "686fb978-404c-445b-b5d4-6a09b2ba458a",
  authority: "https://login.microsoftonline.com/common",
  scopes: [
    'User.Read',
    'User.Read.All',
    'Directory.Read.All',
    'People.Read',
    'People.Read.All',
    'User.ReadBasic.All',
    'Calendars.Read',
    'Files.Read',
    'Files.Read.All',
    'Sites.Read.All',
    'Mail.Read'
  ]
});

// Get the root element
const container = document.getElementById('root');

// Create a root
const root = createRoot(container);

// Initial render
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import ManagerDashboard from './components/ManagerDashboard';
import EmployeeDashboard from './components/EmployeeDashboard';
import { Alert, AlertDescription } from './components/ui/ui-components';
import { AlertTriangle } from 'lucide-react';
import LoginPage from './components/LoginPage';
import { ROUTERS } from './lib/constants';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const { user, loading, isManager, error } = useAuth();

  console.log('App render state:', {
    user,
    loading,
    isManager,
    error
  });

  if (loading) {
    return (
      <div className="initial-loader">
        <div className="text-center">
          <h2 className="form-label">Performance Review Portal</h2>
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-container">
        <Alert variant="destructive">
          <AlertTriangle className="icon" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <Routes>
      {/* Root route - redirects based on auth status and role */}
      <Route
        path="/"
        element={
          user ? (
            isManager ? (
              <Navigate to={ROUTERS.DASHBOARD.MANAGER} replace />
            ) : (
              <Navigate to={ROUTERS.DASHBOARD.EMPLOYEE} replace />
            )
          ) : (
            <Navigate to={ROUTERS.LOGIN} replace />
          )
        }
      />

      {/* Login route */}
      <Route
        path={ROUTERS.LOGIN}
        element={
          user ? (
            isManager ? (
              <Navigate to={ROUTERS.DASHBOARD.MANAGER} replace />
            ) : (
              <Navigate to={ROUTERS.DASHBOARD.EMPLOYEE} replace />
            )
          ) : (
            <LoginPage />
          )
        }
      />

      {/* Protected dashboard routes */}
      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTERS.DASHBOARD.MANAGER}
          element={
            isManager ? (
              <ManagerDashboard />
            ) : (
              <Navigate to={ROUTERS.DASHBOARD.EMPLOYEE} replace />
            )
          }
        />
        <Route
          path={ROUTERS.DASHBOARD.EMPLOYEE}
          element={<EmployeeDashboard />}
        />
      </Route>

      {/* Catch all unmatched routes */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
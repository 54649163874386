// API Endpoints
export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: '/api/auth/login',
    LOGOUT: '/api/auth/logout',
    REFRESH_TOKEN: '/api/auth/refresh-token'
  },
  USERS: {
    ME: '/api/users/me',
    MANAGED: '/api/users/managed',
    BY_ID: (id) => `/api/users/${id}`
  },
  REVIEWS: {
    BASE: '/api/reviews',
    MANAGER: '/api/reviews/manager',
    EMPLOYEE: '/api/reviews/employee',
    BY_ID: (id) => `/api/reviews/${id}`
  }
};

// Application Routes
export const ROUTERS = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  REVIEWS: {
    CREATE: '/reviews/create',
    EDIT: (id) => `/reviews/${id}/edit`,
    DETAIL: (id) => `/reviews/${id}`,
    MY_REVIEWS: '/reviews/my-reviews',
    TEAM: '/reviews/team',
    CALENDAR: '/reviews/calendar',
    HISTORY: '/reviews/history'
  },
  SETTINGS: '/settings',
  HELP: '/help',
  PRIVACY: '/privacy',
  TERMS: '/terms'
};

export const REVIEW_STATUS = {
  DRAFT: 'draft',
  IN_PROGRESS: 'in_progress',
  PENDING_EMPLOYEE: 'pending_employee',
  PENDING_MANAGER: 'pending_manager',
  COMPLETED: 'completed',
  ARCHIVED: 'archived'
};

// Readable display names for statuses
export const REVIEW_STATUS_DISPLAY = {
  [REVIEW_STATUS.DRAFT]: 'Draft',
  [REVIEW_STATUS.IN_PROGRESS]: 'In Progress',
  [REVIEW_STATUS.PENDING_EMPLOYEE]: 'Pending Employee Review',
  [REVIEW_STATUS.PENDING_MANAGER]: 'Pending Manager Review',
  [REVIEW_STATUS.COMPLETED]: 'Completed',
  [REVIEW_STATUS.ARCHIVED]: 'Archived'
};

// Status color mappings for UI
export const REVIEW_STATUS_COLORS = {
  [REVIEW_STATUS.DRAFT]: 'gray',
  [REVIEW_STATUS.IN_PROGRESS]: 'yellow',
  [REVIEW_STATUS.PENDING_EMPLOYEE]: 'blue',
  [REVIEW_STATUS.PENDING_MANAGER]: 'purple',
  [REVIEW_STATUS.COMPLETED]: 'green',
  [REVIEW_STATUS.ARCHIVED]: 'gray'
};
import React, { createContext, useState, useContext, useEffect } from 'react';
import { Providers, ProviderState } from '@microsoft/mgt-element';
import { useNavigate } from 'react-router-dom';
import { ROUTERS } from '../lib/constants';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isManager, setIsManager] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [managedUsers, setManagedUsers] = useState([]);
  const navigate = useNavigate();

  const checkManagerStatus = async (graphClient) => {
    try {
      console.log('Checking manager status...');
      const directReports = await graphClient
        .api('/me/directReports')
        .select('id,displayName,jobTitle,mail,userPrincipalName')
        .get();

      console.log('Direct reports:', directReports);
      const reports = directReports.value || [];
      setManagedUsers(reports);
      return reports.length > 0;
    } catch (error) {
      console.error('Error checking direct reports:', error);
      setManagedUsers([]);
      return false;
    }
  };

  const initializeAuth = async () => {
    try {
      console.log('Initializing authentication...');
      const provider = Providers.globalProvider;
      
      if (provider && provider.state === ProviderState.SignedIn) {
        console.log('Provider is signed in, fetching user profile...');
        const graphClient = provider.graph.client;
        
        const userProfile = await graphClient.api('/me').get();
        console.log('User profile:', userProfile);
        setUser(userProfile);

        const managerStatus = await checkManagerStatus(graphClient);
        console.log('Manager status:', managerStatus);
        setIsManager(managerStatus);
      } else {
        console.log('Provider not signed in');
        setUser(null);
        setIsManager(false);
      }
    } catch (err) {
      console.error('Auth initialization failed:', err);
      setError('Failed to initialize authentication');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('Setting up auth provider...');
    const handleProviderStateChanged = async () => {
      const provider = Providers.globalProvider;
      console.log('Provider state changed:', provider?.state);
      
      if (provider && provider.state === ProviderState.SignedIn) {
        await initializeAuth();
      } else {
        setUser(null);
        setIsManager(false);
        setManagedUsers([]);
      }
    };

    Providers.onProviderUpdated(handleProviderStateChanged);
    handleProviderStateChanged();

    return () => {
      Providers.removeProviderUpdatedListener(handleProviderStateChanged);
    };
  }, []);

  const login = async () => {
    try {
      console.log('Attempting login...');
      setError(null);
      const provider = Providers.globalProvider;
      await provider.login();
    } catch (err) {
      console.error('Login failed:', err);
      setError('Failed to sign in. Please try again.');
      throw err;
    }
  };

  const logout = async () => {
    try {
      console.log('Attempting logout...');
      setError(null);
      const provider = Providers.globalProvider;
      await provider.logout();
      setUser(null);
      setIsManager(false);
      setManagedUsers([]);
      navigate(ROUTERS.LOGIN);
    } catch (err) {
      console.error('Logout failed:', err);
      setError('Failed to sign out. Please try again.');
      throw err;
    }
  };

  const value = {
    user,
    isManager,
    loading,
    error,
    managedUsers,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardHeader, CardTitle, CardContent } from './ui/ui-components';
import { AlertTriangle } from 'lucide-react';

const LoginPage = () => {
  const { login, error: authError } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      setLoading(true);
      setError(null);
      await login();
    } catch (err) {
      console.error('Login failed:', err);
      setError('Failed to sign in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="initial-loader">
      <Card>
        <CardHeader>
          <CardTitle>Performance Review Portal</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="form-label">
            <p>Sign in with your Microsoft account to continue</p>
          </div>
          
          {(error || authError) && (
            <div className="form-error">
              <div className="error-content">
                <AlertTriangle className="error-icon" />
                <span>{error || authError}</span>
              </div>
            </div>
          )}

          <button
            onClick={handleLogin}
            disabled={loading}
            className={`btn-primary ${loading ? 'btn-disabled' : ''}`}
          >
            {loading ? (
              <div className="button-content">
                <span className="spinner"></span>
                Signing in...
              </div>
            ) : (
              'Sign in with Microsoft'
            )}
          </button>

          <p className="form-label">
            By signing in, you agree to the company's policies and terms of service
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginPage;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Person, PersonCard } from '@microsoft/mgt-react';
import { ROUTERS } from '../lib/constants';
import { SharePointService } from './SharePointService';

import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent,
  Alert,
  AlertDescription 
} from './ui/ui-components';

import { 
  Users, 
  AlertTriangle, 
  Clock, 
  CheckCircle2, 
  BarChart3,
  Calendar,
  LogOut,
  ClipboardList 
} from 'lucide-react';

const ManagerDashboard = () => {
  const navigate = useNavigate();
  const { user, isManager, logout, managedUsers } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardStats, setDashboardStats] = useState({
    totalEmployees: 0,
    pendingReviews: 0,
    completedReviews: 0,
    upcomingReviews: 0
  });

  useEffect(() => {
    // Redirect if not a manager
    if (!isManager) {
      navigate(ROUTERS.DASHBOARD.EMPLOYEE);
      return;
    }

    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        
        if (user) {
          const sharePointService = new SharePointService();
          const reviewsData = await sharePointService.getReviewsByManager(user.id);
          setReviews(reviewsData);

          const now = new Date();
          const thirtyDaysFromNow = new Date(now.getTime() + (30 * 24 * 60 * 60 * 1000));

          const stats = {
            totalEmployees: managedUsers.length,
            pendingReviews: reviewsData.filter(review => 
              review.status === 'pending' || review.status === 'in_progress'
            ).length,
            completedReviews: reviewsData.filter(review => 
              review.status === 'completed'
            ).length,
            upcomingReviews: reviewsData.filter(review => {
              const reviewDate = new Date(review.nextReviewDate);
              return reviewDate >= now && reviewDate <= thirtyDaysFromNow;
            }).length
          };

          setDashboardStats(stats);
        }
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [user, isManager, managedUsers, navigate]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate(ROUTERS.LOGIN);
    } catch (err) {
      console.error('Logout failed:', err);
      setError('Failed to log out. Please try again.');
    }
  };

  if (loading) {
    return <div className="initial-loader">Loading dashboard...</div>;
  }

  return (
    <div className="page-container">
      {/* Header */}
      <header className="section-spacing">
        <div className="header-content">
          <div>
            <h1 className="form-label">Manager Dashboard</h1>
            <p className="form-label">Welcome back, {user?.displayName}</p>
          </div>
          <button onClick={handleLogout} className="btn-secondary">
            <LogOut className="icon" />
            Sign Out
          </button>
        </div>
      </header>

      <main className="section-spacing">
        {error && (
          <Alert variant="destructive">
            <AlertTriangle className="icon" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* Dashboard Stats */}
        <div className="stats-grid">
          <Card>
            <CardContent>
              <div className="stat-content">
                <div className="stat-icon">
                  <Users />
                </div>
                <div>
                  <p className="form-label">Team Members</p>
                  <p className="stat-value">{dashboardStats.totalEmployees}</p>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Similar cards for other stats... */}
        </div>

        {/* Team Members Table */}
        <Card className="section-spacing">
          <CardHeader>
            <CardTitle>
              <Users className="icon" />
              Team Members
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="table-container">
              <table>
                <thead className="table-header">
                  <tr>
                    <th className="table-header-cell">Employee</th>
                    <th className="table-header-cell">Last Review</th>
                    <th className="table-header-cell">Status</th>
                    <th className="table-header-cell">Next Review</th>
                    <th className="table-header-cell">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {managedUsers.map((employee) => {
                    const employeeReviews = reviews.filter(r => r.employeeId === employee.id);
                    const lastReview = employeeReviews[0];

                    return (
                      <tr key={employee.id} className="table-row">
                        <td className="table-cell">
                          <Person 
                            personQuery={employee.userPrincipalName}
                            view={PersonCard.twolines}
                            showPresence={true}
                            personCardInteraction="hover"
                          />
                        </td>
                        <td className="table-cell">
                          {lastReview ? new Date(lastReview.reviewDate).toLocaleDateString() : 'No reviews'}
                        </td>
                        <td className="table-cell">
                          <span className={`status-badge ${
                            lastReview?.status === 'completed' ? 'status-badge-success' : 
                            lastReview?.status === 'pending' ? 'status-badge-warning' : 
                            'status-badge-error'
                          }`}>
                            {lastReview?.status || 'No Status'}
                          </span>
                        </td>
                        <td className="table-cell">
                          {lastReview?.nextReviewDate ? 
                            new Date(lastReview.nextReviewDate).toLocaleDateString() : 
                            'Not scheduled'}
                        </td>
                        <td className="table-cell">
                          <button
                            onClick={() => navigate(`${ROUTERS.REVIEWS.CREATE}/${employee.id}`)}
                            className="btn-secondary"
                          >
                            Review
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>

        {/* Quick Actions */}
        <div className="quick-actions-grid">
          {/* Quick action cards using existing Card components and styles */}
          <Card className="card-hover" onClick={() => navigate(ROUTERS.REVIEWS.CREATE)}>
            <CardContent>
              <div className="quick-action-content">
                <div>
                  <h3>Start New Review</h3>
                  <p>Create a new performance review</p>
                </div>
                <ClipboardList className="icon" />
              </div>
            </CardContent>
          </Card>

          {/* Similar cards for other quick actions... */}
        </div>
      </main>
    </div>
  );
};

export default ManagerDashboard;